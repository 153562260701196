/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';
import style from './Onboardingcookie.module.scss';
import { RichText } from 'prismic-reactjs';
import Button from '@components/Button/Button.js';
import { navigate } from 'gatsby';
// import Image from '@components/Image/Image';

const OnboardingCookiePage = ({ data, videoask }) => {
  // console.log('videoask into cookiepage', videoask);
  const { body } = data;
  const firstBodyItem = body[0];
  const {
    title,
    description,
    // buttontext,
    // buttonlink,
    buttontext1,
    buttonlink1,
    buttontext2,
    buttonlink2,
    // previewimage: previewImage,
  } = firstBodyItem?.primary;
  // description
  // console.log('body into data for cookie', body);
  // console.log('firstBodyItem', firstBodyItem);

  // buttontext, buttonlink
  const buttonLink = buttonlink1?.text;
  const buttonLink1 = buttonlink2?.text;
  // const buttonLink3 = buttonlink?.text

  const handleClick = (e) => {
    e.preventDefault();
    navigate(`/`);
  };

  const handleClick1 = (e) => {
    e.preventDefault();
    navigate('/onboarding');
  };

  // const handleClick2 = (e,) => {
  //     e.preventDefault();
  //     // navigate('/onboarding');
  // }
  // console.log(modalIsOpen, "modalIsOpen");
  return (
    <div className={style.cookiePage}>
      <section className={style.hero4}>
        <div className={style.wrapper1}>
          <div className={style.title}>
            <RichText render={title?.richText} />
          </div>
          <div className={style.redirect}>
            <Button
              // variant={VARIANT.GRADIENT}
              isHeader={true}
              click={(e) => handleClick(e, buttonLink)}
            >
              <RichText render={buttontext1?.richText} />
            </Button>
            <span className={style.breadcums}>&gt; &gt;</span>
            <Button
              // variant={VARIANT.GRADIENT}
              isHeader={true}
              click={(e) => handleClick1(e, buttonLink1)}
            >
              <RichText render={buttontext2?.richText} />
            </Button>
          </div>
        </div>
        <div className={style.imageOnWrapper}>
          {/* <Image className={style.image} image={previewImage} /> */}
          <div className={style.onboardingvideoWrapper}>
            <iframe
              src={videoask?.raw?.url}
              title="Onboarding Video"
              role="presentation"
              // width="640"
              // height="360"
              width="100%"
              height="360"
              frameBorder="0"
              allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
              allowFullScreen
            />
          </div>
        </div>
        <div className={style.content}>
          <div className={style.allcontent}>
            <div className={style.description}>
              <RichText render={description?.richText} />
            </div>
          </div>
          {/* <div className={style.cookies}>
                        <div className={style.butttonCookie}>
                            <Button
                                variant={VARIANT.GRADIENT}
                                isHeader={true}
                                click={(e) => handleClick2(e, buttonLink3)}
                            >
                                <RichText render={buttontext?.richText} />
                            </Button>
                        </div>
                    </div> */}
        </div>
      </section>
    </div>
  );
};

export default OnboardingCookiePage;

OnboardingCookiePage.propTypes = {
  data: PropTypes.object.isRequired,
  videoask: PropTypes.object.isRequired,
};
