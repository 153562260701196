import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '@components/Layout';
import OnboardingCookiePage from '../scenes/OnboardingCookiePage/OnboardingCookiePage';

const Page = ({ data }) => {
  // console.log(data, '-data');
  const onboardingCookieContent =
    data?.allPrismicOnboardingSecurePage?.edges[0];
  if (!onboardingCookieContent) return null;
  const onSecurePage = onboardingCookieContent?.node;
  const {
    uid,
    id,
    lang,
    type,
    alternate_languages,
    data: pageData,
  } = onSecurePage;

  const { metatitle, metadescription, canonical } = pageData;
  const activeDocMeta = { id, uid, lang, type, alternate_languages };
  // console.log('data', pageData);
  // console.log('activeDocMetain Onboarding', activeDocMeta);

  return (
    <Layout
      activeDocMeta={activeDocMeta}
      metatitle={metatitle}
      metadescription={metadescription}
      canonical={canonical}
    >
      <OnboardingCookiePage
        data={pageData}
        videoask={pageData.videoask}
        metatitle={metatitle}
        canonical={canonical}
      />
    </Layout>
  );
};

Page.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  videoask: PropTypes.object.isRequired,
};

export const query = graphql`
  query($uid : String) {
    allPrismicOnboardingSecurePage(filter: { uid: { eq: $uid }}) {
      edges {
        node {
          uid
          lang
          id
          type
          alternate_languages {
            lang
            id
            type
            uid
          }
          data {
            metatitle {
              text
            }
            metadescription {
              text
            }
            canonical {
              text
            }
            body {
              ... on PrismicOnboardingSecurePageDataBodyHero {
                id
                slice_type
                primary {
                  title {
                    richText
                  }
                  description {
                    richText
                  }
                  buttontext1 {
                    richText
                  }
                  buttonlink1 {
                    richText
                  }
                  buttontext2 {
                    richText
                  }
                  buttonlink2 {
                    richText
                  }
                  previewimage {
                    url
                    alt
                    gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
                  }
                }
              }
            }
            videoask {
              raw
            }
          }
        }
      }
    }
  }
`;

export default Page;
